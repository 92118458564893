/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LandingPageRegistrationForm } from './types';
import {
  checkEmailValidity,
  handleNameKeyDown,
} from '../Component/AdminSrc/Utils/service';
import {
  checkPhoneValidity,
  generateRecaptchaToken,
  handleMenuLinkClick,
  removeContactFormate,
  validateFirstAndLastName,
} from '../Utils/service';
import {
  createWaitListCompany,
  getGoogleMapAddressSuggestion,
} from '../Redux/Slice/landingPageSlice';
import Loader from '../Component/CommonComponent/loader';
import TextFieldError from '../Component/CommonComponent/textFieldError';
import RadioBlank from '../Assets/img/radioBlank';
import RadioSelected from '../Assets/img/radioSelected';
import { ReCaptchaActionType } from '../Enum';
import TitleIcon from '../Assets/img/titleIcon';
import { RootState } from '../Redux/Store/store';

const ContactUs = () => {
  const displayEmptySelect = 'Select';
  const { industryData } = useSelector((state: RootState) => state.industry);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [industry, setIndustry] = useState('');
  const [userCount, setUserCount] = useState('');
  const [crm, setCrm] = useState('');
  const [addressSuggestion, setAddressSuggestion] = useState([]);
  const [businessAddressValue, setBusinessAddressValue] = useState('');
  const [isBusinessAddressFocused, setIsBusinessAddressFocused] =
    useState(false);
  const [agreedTermsAndCondition, setAgreedTermsAndCondition] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [agreedToReceiveNotifications, setAgreedToReceiveNotifications] =
    useState(false);
  const [industryList, setIndustryList] = useState<string[]>([]);
  const initialValue = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    businessName: '',
    businessAddress: '',
    email: '',
    crm: '',
    primaryInterest: '',
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<LandingPageRegistrationForm>({
    defaultValues: initialValue,
    mode: 'onChange',
  });

  const fields = watch();

  const defaultCRM = ['ServiceTitan', 'Cera', 'Housecall Pro', 'None'];

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleIndustryChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const filteredValue = (value as string[]).filter(
      (val) => val !== displayEmptySelect,
    );

    setIndustryList(filteredValue);
  };

  const handleCreateWaitListCompany = async (companyData) => {
    setIsApiCalling(true);
    try {
      const waitListCompany = await createWaitListCompany(companyData);
      if (waitListCompany.status === 200) {
        toast.success(
          'Thank you for showing interest in the Home Service Checklist! Our team will get back to you shortly.',
        );
        reset(initialValue);
        setIndustry('');
        setIndustryList([]);
        setAddressSuggestion([]);
        setBusinessAddressValue('');
        setCrm('');
        setUserCount('');
        setAgreedTermsAndCondition(false);
        setAgreedToReceiveNotifications(false);
      }
    } catch (error) {
      console.log('🚀 Error:', error);
      setSubmitted(false);
    } finally {
      setIsApiCalling(false);
    }
  };

  const submitResponse = async (fieldsValue: LandingPageRegistrationForm) => {
    const minUserCount = Number(userCount);
    setSubmitted(true);
    if (!agreedTermsAndCondition) return;
    if (!industry) return;
    setIsApiCalling(true);
    const maxUsersMapping = {
      1: 10,
      11: 50,
      51: 100,
    };
    const industryIds = industryList.map(
      (industryName) =>
        industryData.response.items.find(
          (industryDetail) => industryDetail.name === industryName,
        ).id,
    );
    debounce(
      handleCreateWaitListCompany({
        ...fieldsValue,
        email: fieldsValue.email.trim(),
        phoneNumber: removeContactFormate(fieldsValue.phoneNumber),
        industries: industryIds,
        agreedToReceiveNotifications,
        concent: true,
        maxExpectedCompanyUsers: maxUsersMapping[minUserCount] || null,
        minExpectedCompanyUsers: minUserCount,
        crm: crm?.trim(),
        captchaToken: await generateRecaptchaToken(
          ReCaptchaActionType.JOIN_WAITLIST,
        ),
      }),
      2000,
    );
    setSubmitted(false);
  };

  useEffect(() => {
    if (businessAddressValue.trim().length < 3) {
      setAddressSuggestion([]);
    }
    const delayDebounceFn = setTimeout(async () => {
      if (businessAddressValue.trim().length >= 3) {
        const res = await getGoogleMapAddressSuggestion({
          input: businessAddressValue,
        });
        setAddressSuggestion(res?.response?.predictions || []);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [businessAddressValue]);

  return (
    <Box className="container-padding m-auto" maxWidth={708}>
      <Loader loading={isApiCalling} />
      <Box
        className="contact-section-title section-title d-flex align-items-start justify-content-start"
        mb={3}
      >
        <TitleIcon />
        <Typography
          className="fs-40 fw-bold color-black1"
          ml={2}
          onClick={() => {
            handleMenuLinkClick('contactUsSection');
          }}
        >
          Join the interest list!
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit(submitResponse)}>
          <Grid container spacing={{ xs: 2, md: 2.5 }}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  First Name <span className="req">* </span>
                </Typography>
                <TextField
                  variant="outlined"
                  onKeyDown={handleNameKeyDown}
                  name="firstName"
                  {...register('firstName', {
                    validate: (value) => validateFirstAndLastName(value, true),
                  })}
                  error={!!errors.firstName}
                  helperText={errors.firstName && errors.firstName.message}
                  placeholder="Tommy"
                  autoComplete="off"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  Last Name <span className="req">* </span>
                </Typography>
                <TextField
                  variant="outlined"
                  name="lastName"
                  onKeyDown={handleNameKeyDown}
                  {...register('lastName', {
                    validate: (value) => validateFirstAndLastName(value, false),
                  })}
                  error={!!errors.lastName}
                  helperText={errors.lastName && errors.lastName.message}
                  placeholder="Mello"
                  autoComplete="off"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  Email <span className="req">* </span>
                </Typography>
                <TextField
                  variant="outlined"
                  inputProps={{
                    autoCapitalize: 'none',
                  }}
                  name="email"
                  {...register('email', {
                    validate: (value) => checkEmailValidity(value),
                  })}
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  placeholder="email@domain.com"
                  autoComplete="off"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  Phone Number <span className="req">* </span>
                </Typography>
                <Controller
                  rules={{
                    validate: (value) => checkPhoneValidity(value).message,
                  }}
                  render={({ field }) => (
                    <PatternFormat
                      {...field}
                      autoComplete="off"
                      inputProps={{ inputMode: 'numeric' }}
                      customInput={TextField}
                      format="(%%%) %%%-%%%%"
                      patternChar="%"
                      variant="outlined"
                      error={!!errors.phoneNumber}
                      helperText={
                        errors.phoneNumber && errors.phoneNumber.message
                      }
                      placeholder="123-456-7890"
                    />
                  )}
                  name="phoneNumber"
                  control={control}
                />
                {/* <TextField
                  variant="outlined"
                  name="phoneNumber"
                  {...register('phoneNumber', {
                    required: 'Phone number is required.',
                  })}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber && errors.phoneNumber.message}
                /> */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  Company Name <span className="req">* </span>
                </Typography>
                <TextField
                  variant="outlined"
                  name="businessName"
                  {...register('businessName', {
                    required: 'Company name is required.',
                  })}
                  error={!!errors.businessName}
                  helperText={
                    errors.businessName && errors.businessName.message
                  }
                  placeholder="A1 Garage"
                  autoComplete="off"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  Company Address <span className="req">* </span>
                </Typography>
                <TextField
                  variant="outlined"
                  name="businessAddress"
                  placeholder="123 Fake Street Chicago, IL"
                  {...register('businessAddress', {
                    required: 'Company address is required.',
                    onBlur() {
                      setTimeout(() => {
                        setIsBusinessAddressFocused(false);
                      }, 200);
                    },
                    onChange(event) {
                      setValue('businessAddress', event.target.value);
                      setBusinessAddressValue(event.target.value);
                      setIsBusinessAddressFocused(true);
                    },
                  })}
                  onFocus={() => {
                    setIsBusinessAddressFocused(true);
                  }}
                  error={!!errors.businessAddress}
                  helperText={
                    errors.businessAddress && errors.businessAddress.message
                  }
                  autoComplete="off"
                />
                <Box
                  className="autocomplete-address"
                  sx={{
                    position: 'absolute',
                    display:
                      addressSuggestion.length &&
                      isBusinessAddressFocused &&
                      businessAddressValue.trim().length >= 3
                        ? ''
                        : 'none',
                  }}
                >
                  <Box className="autocomplete-address-wrap">
                    {addressSuggestion.map((res, i) => (
                      <Typography
                        key={i}
                        p={1}
                        className="cursor-pointer"
                        onMouseDown={async () => {
                          setFocus('businessAddress');
                          setValue('businessAddress', res.description);
                          setBusinessAddressValue(res.description);
                          setIsBusinessAddressFocused(false);
                        }}
                      >
                        {res.description}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                '& .text-danger': { marginTop: '5px', marginLeft: '5px' },
              }}
            >
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  Industry <span className="req">* </span>
                </Typography>
                <Select
                  id="select-subcategory"
                  className="sub-cat-menu"
                  multiple
                  value={
                    industryList.length ? industryList : [displayEmptySelect]
                  }
                  onChange={(e) => {
                    setIndustry(String(e.target.value));
                    handleIndustryChange(e);
                  }}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    disableScrollLock: true,
                    disableAutoFocusItem: true,
                    PaperProps: {
                      sx: {
                        borderRadius: '8px',
                        padding: '13px 11px',
                        '& .MuiList-root': {
                          maxHeight: '181px',
                          overflowY: 'auto',
                          '& .MuiMenuItem-root': {
                            padding: '0px',
                          },
                          '&::-webkit-scrollbar': {
                            width: '4px',
                            borderRadius: '4px',
                            marginRight: '4px',
                          },
                          '&::-webkit-scrollbar-track': {
                            background: '#D9D9D9',
                            borderRadius: '6px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: 'var(--blue)',
                            borderRadius: '6px',
                          },
                        },
                      },
                    },
                  }}
                >
                  {industryData?.response?.items?.map((item, index) => (
                    <MenuItem
                      key={index}
                      className="bg-transparent"
                      value={item.name}
                    >
                      <Checkbox
                        checked={industryList.indexOf(item.name) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!industryList.length && submitted && (
                <TextFieldError error="Industry name is required" />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  CRM <span className="req">* </span>
                </Typography>
                <Autocomplete
                  disablePortal
                  freeSolo
                  options={defaultCRM}
                  forcePopupIcon
                  value={crm}
                  onChange={(e: any) => {
                    setCrm(e.target.textContent);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Ex. Cera"
                      name="crm"
                      onChange={(e: any) => {
                        setCrm(e.target.value);
                      }}
                    />
                  )}
                  sx={{
                    '& .MuiAutocomplete-popupIndicator:hover': {
                      background: 'none',
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                      display: 'none',
                    },
                    '& input': {
                      padding: '0 !important',
                    },
                    '& .MuiSvgIcon-root': {
                      fill: 'var(--gray1)',
                    },
                    ':hover svg path': {
                      fill: 'var(--gray1) !important',
                    },
                    '& .MuiInputBase-root': {
                      padding: '9px 16px',
                    },
                  }}
                  clearIcon={false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className="field-wrap">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  User count <span className="req">* </span>
                </Typography>
                <Select
                  id="select-subcategory"
                  variant="outlined"
                  value={userCount}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUserCount(e.target.value);
                  }}
                  MenuProps={{
                    disableScrollLock: true,
                    disableAutoFocusItem: true,
                  }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="1">1 - 10</MenuItem>
                  <MenuItem value="11">11 - 50</MenuItem>
                  <MenuItem value="51">51 - 100</MenuItem>
                  <MenuItem value="100"> 100+</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className="field-wrap interest-field">
                <Typography className="fs-18 fw-bold" mb={1.25}>
                  Primary Interest <span className="req">* </span>
                </Typography>
                <Controller
                  name="primaryInterest"
                  control={control}
                  rules={{ required: 'Primary interest is required.' }}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue('primaryInterest', e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="Demo of Home Service Checklist"
                        tabIndex={-1}
                        control={
                          <Radio
                            icon={<RadioBlank />}
                            checkedIcon={<RadioSelected />}
                          />
                        }
                        label="Demo of Home Service Checklist"
                        className="fs-18"
                      />
                      <FormControlLabel
                        value="Becoming a pilot customer"
                        tabIndex={-2}
                        control={
                          <Radio
                            icon={<RadioBlank />}
                            checkedIcon={<RadioSelected />}
                          />
                        }
                        label="Becoming a pilot customer"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
              {!fields.primaryInterest && submitted && (
                <TextFieldError
                  error={
                    errors.primaryInterest && errors.primaryInterest.message
                  }
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedTermsAndCondition}
                    onChange={(e) => {
                      setAgreedTermsAndCondition(Boolean(e.target.checked));
                    }}
                  />
                }
                label={
                  <Typography>
                    I accept the
                    <Link
                      to={`${process.env.REACT_APP_CDN_URL}/terms.pdf`}
                      target="_blank"
                      className="color-blue"
                      style={{ paddingLeft: '5px', paddingRight: '5px' }}
                    >
                      terms of use
                    </Link>
                    and
                    <Link
                      to={`${process.env.REACT_APP_CDN_URL}/privacy-policy.pdf`}
                      target="_blank"
                      className="color-blue"
                      style={{ paddingLeft: '5px' }}
                    >
                      privacy policy
                      <span className="req fw-bold">* </span>
                    </Link>
                  </Typography>
                }
                className="contact-checkbox fs-18 "
              />
              {!agreedTermsAndCondition && submitted && (
                <TextFieldError error="Please accept terms & condition" />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedToReceiveNotifications}
                    onChange={(e) => {
                      setAgreedToReceiveNotifications(e.target.checked);
                    }}
                  />
                }
                label="I’d like to receive product update emails from TMV."
                className="contact-checkbox fs-18 "
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: '32px' }}
            disabled={
              isApiCalling ||
              !isValid ||
              !agreedTermsAndCondition ||
              !crm ||
              !userCount ||
              !industryList.length
            }
            onClick={() => {
              setSubmitted(true);
            }}
          >
            Submit Request
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ContactUs;
