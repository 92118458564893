import { Box, Grid, Typography } from '@mui/material';
import ServiceSlider from './serviceSlider';
import HomeServiceSlider from './homeServiceSlider';
import TitleIcon from '../Assets/img/titleIcon';

const FeaturesComponent = () => (
  <Box className="landing-page-container">
    <Grid container spacing={0} className="align-items-center">
      <Grid
        item
        xs={12}
        lg={6}
        className="home-service-text-content d-flex justify-content-center justify-lg-content-end"
        pr={{ lg: 6.5, xs: 1.875 }}
        pl={1.875}
      >
        <Box
          className="w-100"
          maxWidth={{ lg: 585, sm: 780, xs: '100%' }}
          padding={{ sm: '10px 0px 10px', xs: '40px 0' }}
        >
          <Box className="section-title d-flex align-items-start" mb={4.25}>
            <TitleIcon />
            <Typography className="fs-40 fw-bold color-black1" ml={2}>
              Designed For Home Service Businesses
            </Typography>
          </Box>

          <Box className="home-service-slider">
            <HomeServiceSlider />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        p="20px 0px 0px"
        className="background-secondary position-relative service-slider-block"
      >
        <ServiceSlider />
      </Grid>
    </Grid>
  </Box>
);
export default FeaturesComponent;
