import { Box, Button, Grid, Typography } from '@mui/material';
import { handleMenuLinkClick } from '../Utils/service';
import TopBanner from '../Assets/img/topBanner';
import TitleIcon from '../Assets/img/titleIcon';

const JobComponent = () => (
  <Box className="landing-page-container">
    <Grid container spacing={0} className="align-items-center">
      <Grid
        item
        xs={12}
        lg={6}
        className="job-text-content d-flex justify-content-center justify-lg-content-end"
        pr={{ lg: 6.5, xs: 1.785 }}
        pl={1.875}
      >
        <Box
          className=""
          maxWidth={{ lg: 590, sm: '710px', xs: '100%' }}
          py={3.5}
        >
          <Box className="section-title d-flex align-items-start" mb={3.5}>
            <TitleIcon />
            <Typography className="fs-40 fw-bold color-black1" ml={2}>
              Eliminate Costly Mistakes & Unnecessary Callbacks
            </Typography>
          </Box>

          <Typography className="fs-18 pera-text color-black1">
            Ever wonder how your competition delivers flawless service every
            single time while your technicians struggle to keep up? Well the
            answer is simple—they have systems in place to ensure that nothing
            falls through the cracks. That’s why we created Home Service
            Checklist—it’s your shortcut to implementing those same powerful and
            effective systems in your business to guarantee consistent,
            error-free results on every service call.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            Home Service Checklist helps eliminate costly mistakes that can hurt
            your business. By ensuring that no task is ever missed you can avoid
            unnecessary callbacks that frustrate customers and lead to
            notoriously bad reviews. The truth? Even your most veteran
            technicians can miss tasks because they too are prone to human
            error. But with our simple yet powerful checklists, they’ll have the
            tools they need to get it right every single time.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            That’s why we developed a fully customizable home services checklist
            to help your team deliver a thorough, consistent result job after
            job. This helps your team avoid those mistakes on service calls that
            can hurt your reputation, destroy trust, crush profits and cause
            operational chaos. The right systems aren’t just essential for your
            business to survive—they’re the key to making it thrive.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            Give your technicians the confidence they need to repeatedly deliver
            a high-quality service with proven pre-built templates that are
            fully customizable to your company’s specific needs.{' '}
          </Typography>
          <Button
            variant="outlined"
            className="fw-normal d-block"
            sx={{
              padding: '7px 15.5px !important',
              marginTop: '28px',
              fontSize: '16px',
            }}
            onClick={() => {
              handleMenuLinkClick('contactUsSection');
            }}
          >
            Join the interest list
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        className="job-image-wrap d-flex justify-content-center"
      >
        <TopBanner />
      </Grid>
    </Grid>
  </Box>
);

export default JobComponent;
