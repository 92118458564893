import { Box, Typography } from '@mui/material';
import TitleIcon from '../Assets/img/titleIcon';

const Faq = () => (
  <Box
    className="m-auto goToId container-padding"
    maxWidth={1284}
    py={{ sm: 7, xs: 5 }}
  >
    <Box
      className="section-title d-flex align-items-start align-items-center justify-content-center"
      mb={5.25}
    >
      <TitleIcon />
      <Typography className="fs-40 fw-bold color-white color-black1" ml={2}>
        FAQs
      </Typography>
    </Box>
    <Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          1—How Much Does It Cost?
        </Typography>
        <Typography className="color-white fs-18">
          Home Service Checklist is in its pre-launch phase. We’re gathering
          feedback from people like you to improve the product. We plan to
          release pricing in early 2025.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          2—How Long Does It Take To Set It Up?
        </Typography>
        <Typography className="color-white fs-18">
          Set up typically takes just a few hours or more depending on the level
          of customization needed. Our onboarding team works closely with you to
          get the system to work as intended for your specific needs.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          3—Will It Integrate With Popular CRMs?
        </Typography>
        <Typography className="color-white fs-18">
          Yes. Home Service Checklist will integrate with most popular CRM
          systems. We’re rolling these integrations out on a planned schedule
          between now and early 2025.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          4—Can I Customize The Checklists For My Business?
        </Typography>
        <Typography className="color-white fs-18">
          Absolutely! You can fully customize the checklists to fit your
          company’s needs. This helps your technicians follow preconfigured
          procedures every time.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          5—Does It Work On Mobile Phones?
        </Typography>
        <Typography className="color-white fs-18">
          Yes. Home Service Checklist is designed for on-the-go mobile usage. It
          lets your technicians instantly access checklists, historical records,
          and all the tools from anywhere at any time.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          6—Does It Work Without An Internet Connection?
        </Typography>
        <Typography className="color-white fs-18">
          Yes. Home Service Checklist works without an internet connection. And
          your technicians can access and update information even when they’re
          offline. Once reconnected, the system automatically synchronizes all
          the updates.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          7—How Do Real-Time Updates Work?
        </Typography>
        <Typography className="color-white fs-18">
          Updates to job statuses and progress reports happen in real-time, even
          while your technicians are on-the-go. These updates instantly notify
          back-office team members to keep everyone on the same page.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          8—Which Industries Do The Premade Templates Cover?
        </Typography>
        <Typography className="color-white fs-18">
          We’ve included hundreds of industry-specific templates designed by
          leading owner-operators in the HVAC, plumbing, electrical, and other
          service industries. Every template can be customized to suit your
          company’s specific needs.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          9—How Does Customer Approval Work?
        </Typography>
        <Typography className="color-white fs-18">
          During each job, technicians collect customer approvals and signatures
          directly through the mobile Home Service Checklist app. This ensures
          accurate service verification, clear communication, and record keeping
          every step of the way.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          10—How Much Support Does This Include?
        </Typography>
        <Typography className="color-white fs-18">
          Our team offers ongoing technical support to implement simple, yet
          powerful systems for your entire team. This includes real-time
          recommendations and guidance from our advisors. We help your
          technicians complete their jobs quickly and efficiently.
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default Faq;
