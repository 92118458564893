import { Box, Grid, Typography } from '@mui/material';
import TitleIcon from '../Assets/img/titleIcon';
import ServiceIcon1 from '../Assets/img/serviceIcon1';
import ServiceIcon2 from '../Assets/img/serviceIcon2';
import ServiceIcon3 from '../Assets/img/serviceIcon3';

const serviceData = [
  {
    image: <ServiceIcon1 />,
    title: 'Never Miss Another Task',
    description:
      'Our step-by-step checklists provide fail-proof instructions so that your team can execute every task flawlessly every single time. Your technicians will never miss another task again.',
  },
  {
    image: <ServiceIcon2 />,
    title: 'Real-Time Task Assistance',
    description:
      'Give your technicians an unfair advantage with access to proprietary troubleshooting and expert task-assistance guides. Handle routine maintenance or complex jobs with ease.',
  },
  {
    image: <ServiceIcon3 />,
    title: 'Professional Pre-Made Templates',
    description:
      'Never build from scratch. Use our extensive library of professional, pre-made templates. They’re 100% editable and fully customizable to your specific needs. Save hundreds of hours in lost time.',
  },
];

const ServiceComponent = () => (
  <Box className="m-auto color-white container-padding" maxWidth={1146}>
    <Box
      className="section-title d-flex align-items-start justify-content-center"
      mb={3}
    >
      <TitleIcon />
      <Typography className="fs-40 fw-bold color-white" ml={2}>
        Turn Every Service Call Into Reliable Customer Wins
      </Typography>
    </Box>

    <Box className="text-center" mt={6}>
      <Grid
        container
        spacing={{ md: 7, sm: 3, xs: 2 }}
        className="justify-content-center"
      >
        {serviceData.map((service: any) => (
          <Grid item xs={12} sm={6} md={4} key={service.title}>
            <Box className="service-icon d-flex align-items-center justify-content-center m-auto rounded-100">
              {service.image}
            </Box>
            <Typography className="color-white fs-24 fw-semibold mb-1">
              {service.title}
            </Typography>
            <Typography className="color-white pera-text fs-18">
              {service.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Box>
);

export default ServiceComponent;
