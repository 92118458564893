import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const HomeServiceSlider = () => (
  <Swiper
    spaceBetween={10}
    slidesPerView={1}
    pagination={{
      clickable: true,
    }}
    mousewheel
    keyboard
    modules={[Pagination]}
    autoplay
    loop
  >
    <SwiperSlide>
      <ul className="home-service-items fs-18 pera-text color-black1">
        <li>
          <strong>Simple & Easy-to-Use:</strong> Fully customizable,
          quick-launch checklists that get your team up and running in no time
          at all with minimal training required.
        </li>
        <li>
          <strong>Mobile Accessibility:</strong> Your technicians have instant
          access to a full-suite of tools while on-the-go, allowing them to stay
          connected and efficient on any task, big or small.
        </li>
        <li>
          <strong>Customizable Templates:</strong> Quickly create and tailor
          service-related checklists to your specific needs to ensure accuracy
          and consistency on every job site.
        </li>
        <li>
          <strong>Organized Task Management:</strong> Give your technicians
          simple-to-follow steps by organizing tasks into categories and
          subcategories for efficient management.
        </li>
      </ul>
    </SwiperSlide>

    <SwiperSlide>
      <ul className="home-service-items fs-18 pera-text color-black1">
        <li>
          <strong>Industry-Specific Templates:</strong> Instantly access
          ready-to-go, fully customizable industry-specific templates for
          plumbing, HVAC, and electrical.
        </li>
        <li>
          <strong>Service History & Records:</strong> Quickly access historical
          records and detailed service records, giving your technicians vital
          information anywhere, any time.
        </li>
        <li>
          <strong>Job Specific Checklists:</strong> Make sure your technicians
          never miss a task again with detailed checklists and clearly-defined
          tasks for each job.
        </li>

        <li>
          <strong>Real-Time Updates: </strong> Technicians can update job status
          and progress reports in real-time, sending instant notifications to
          your back office for better coordination.
        </li>
      </ul>
    </SwiperSlide>
    <SwiperSlide>
      <ul className="home-service-items fs-18 pera-text color-black1">
        <li>
          <strong>Offline Access:</strong> No internet? No problem. Even in
          rural areas with no internet connection, technicians can access and
          update job information while offline, with synchronization once online
          again.
        </li>
        <li>
          <strong>Customer Approval:</strong> Collect customer approvals and
          signatures upon job completion to ensure service verification,
          accurate record keeping, and customer satisfaction on every job.
        </li>
        <li>
          <strong>Backoffice Support:</strong> Allow senior service advisors to
          provide real-time recommendations to technicians in the field to
          ensure every job is handled smoothly & efficiently.
        </li>
        <li>
          <strong>Customized Pricebook: </strong> Offer customized &
          pre-packaged service options to customers, allowing them to easily
          accept or decline packages in real-time on the spot.
        </li>
      </ul>
    </SwiperSlide>
    <SwiperSlide>
      <ul className="home-service-items fs-18 pera-text color-black1">
        <li>
          <strong>AI Generated Tech Summaries:</strong> Generate detailed
          summaries automatically that are based on technician evaluations &
          comments, saving you time, increasing efficiency and ensuring accurate
          recordkeeping.
        </li>
        <li>
          <strong>Extensive Knowledge Base:</strong> System administrators can
          easily upload support documents and assets to give technicians in the
          field access to all the resources they need.
        </li>
        <li>
          <strong>Job Summary PDFs: </strong> Instantly generate, save and email
          PDF job summaries that provide a clear historical record of work
          completed for internal and customer use.
        </li>
      </ul>
    </SwiperSlide>
  </Swiper>
);

export default HomeServiceSlider;
