import { Box, Grid, Typography } from '@mui/material';
import AboutImage from '../Assets/img/aboutImg';
import TitleIcon from '../Assets/img/titleIcon';

const AboutUs = () => (
  <Box className="landing-page-container">
    <Grid container className="align-items-center">
      <Grid
        item
        xs={12}
        lg={6}
        className="d-flex justify-content-center justify-lg-content-end"
        pr={{ lg: 6.5, xs: 1.875 }}
        pl={1.875}
      >
        <Box
          maxWidth={{ lg: 585, sm: '710px', xs: '100%' }}
          padding={{ lg: '12px 0px 0px', xs: '40px 0px 25px' }}
        >
          <Box className="section-title d-flex align-items-start" mb={4.25}>
            {/* <img src={TitleIcon} alt="title-icon" /> */}
            <TitleIcon />
            <Typography className="fs-40 fw-bold color-black1" ml={2}>
              Who We Are
            </Typography>
          </Box>
          <Typography className="fs-18 pera-text color-black1">
            Home Service Checklist was developed and created by two industry
            titans—Joe Cunningham and Tommy Mello. This joint venture brings
            together more than 50 years of in-the-trenches effort and expertise
            in the home services industry.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            Joe, an industry icon, is a veteran with over 30 years of
            boots-on-the-ground experience and a member of the HVAC Hall of
            Fame. Tommy, the visionary founder of legendary A1 Garage Door
            Company, operates the first-ever garage door services business to
            reach over $200 million in annual revenue.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            Together, they’ve created Home Service Checklist—a
            fully-customizable tool designed to help deliver top-quality service
            on every single service call without fail. From your newest,
            “greenest” technicians, to your most seasoned veterans, Home Service
            Checklist helps your business avoid costly mistakes, reduce
            unnecessary callbacks, and eliminate customer frustration by never
            missing a task or necessary step again.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            Ready to see how Home Service Checklist can change the game in your
            home service business? Contact us for a one-on-one virtual
            demonstration—you won’t be disappointed!
          </Typography>
        </Box>
      </Grid>
      <Grid className="about-image-wrap position-relative" item xs={12} lg={6}>
        <Box
          className="position-relative m-auto d-flex justify-content-center"
          maxWidth={{ sm: '710px', xs: '100%' }}
        >
          <Typography className="developer-1 fs-28 color-white fw-semibold">
            Tommy Mello
          </Typography>
          <AboutImage />
          <Typography className="developer-2 fs-28 color-white fw-semibold">
            Joe Cunningham
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default AboutUs;
